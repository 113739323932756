import { LoginResult } from '../models/Result';
import { Credentials } from '../models/Credentials';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConstantsService } from './../app.module';
import jwt_decode, { JwtDecodeOptions, JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private readonly _claim = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';

  // token: string =  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.MfCeRENW03FnKzGqKFv7PMGXrysmxlGUw5JtnQE2x58';
  private _token: string;
  private _name: string;
  private _surname: string;
  private _emailAddress: string;
  private _sid: string;



  public get token() {
    return this._token;
  }
  public get name() {
    return this._name;
  }
  public get surname() {
    return this._surname;
  }
  public get emailAddress() {
    return this._emailAddress;
  }
  public get sid() {
    return this._sid;
  }

  constructor(private http: HttpClient, private constants: ConstantsService) {
  }

  public isAuthenticated(): boolean {    // Get token from localstorage
    this._token = localStorage.getItem('token');    // Check if token is null or empty
    if (this._token) {
      const decoded: JwtPayload = jwt_decode(this._token);
      this._name = decoded[`${this._claim}name`];
      this._surname = decoded[`${this._claim}surname`];
      this._emailAddress = decoded[`${this._claim}emailaddress`];
      this._sid = decoded[`${this._claim}sid`];
      const expire = decoded.exp * 1000;
      return expire > (new Date()).getTime();
    }
    else {
      return false
    }
  }

  login(credentials: Credentials): Observable<LoginResult> {
    //https://localhost:7236/api/Login
    //https://lfapi.it/api/Login
    return this.http.post<LoginResult>(`${this.constants.URL_BASE}api/Login`, credentials).pipe(tap((val) => {
      if (val?.isSuccess) {
        localStorage.setItem('token', val.token);
      }
    }));
  }
}
