<div *ngIf="showCreatePGN; else elseBlock" class="container-home">
  <div style="height: calc(8vh);">
    <input style="height: calc(8vh);" readonly (input)="onInputChange($event)" class="input" value={{valueTextInserted}}
      placeholder={{placeholder}} />
  </div>
  <div style="max-width: 30%; float: right;margin-right: 5%; margin-top: 2%;" class="boxButton">
    <button style="margin-top: 2%;" (click)="savePGN()" mat-raised-button color="primary">Salva Partita</button>
    <button style="margin-top: 2%;" (click)="undo()" mat-raised-button color="warn">Canc mossa</button>
  </div>
  <div class="list-moves" [innerHTML]="pgn"></div>

</div>

<ng-template #elseBlock>
  <mat-card class="start-game-card">
    <mat-card-content>
      <div class="start-game-container">
        <mat-form-field style="margin-right: 2%">
          <!-- <mat-label>Enter some input</mat-label> -->
          <input matInput #input maxlength="10" [value]="myName" readonly>
          <!-- <mat-hint align="end">{{input.value.length}}/10</mat-hint> -->
        </mat-form-field>
        <mat-form-field>
          <mat-label>Colore</mat-label>
          <mat-select name="me" [(ngModel)]="myColourIsWhite" (selectionChange)="selectionChange($event)">
            <mat-option [value]="true">Bianco</mat-option>
            <mat-option [value]="false">Nero</mat-option>
          </mat-select>
          <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
        </mat-form-field>
        <mat-form-field style="margin-right: 2%">
          <mat-label>Nome Avversario</mat-label>
          <input [(ngModel)]="opponentName" matInput #input maxlength="10">
          <!-- <mat-hint align="end">{{input.value.length}}/10</mat-hint> -->
        </mat-form-field>
        <mat-form-field>
          <mat-label>Colore</mat-label>
          <mat-select name="opponent" [(ngModel)]="opponentColour" (selectionChange)="selectionChange($event)">
            <mat-option [value]="true">Bianco</mat-option>
            <mat-option [value]="false">Nero</mat-option>
          </mat-select>
          <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <!-- [disabled]="email.invalid || password.invalid" -->
      <button [disabled]="!opponentName || myColourIsWhite == undefined" (click)="startGame()" class="button-login"
        mat-raised-button color="primary">
        Inzia partita
      </button>
      <!-- <p class="register">
      <button mat-flat-button>Registrati</button>
    </p> -->
      <br />
    </mat-card-actions>
  </mat-card>
  <!-- <div class="example-container">
    <form (ngSubmit)="login()">
      <mat-form-field floatLabel="always">
        <mat-label>Email</mat-label>
        <input matInput [formControl]="form.controls['email']" required />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Password</mat-label>
        <input matInput [formControl]="form.controls['password']" [type]="hide ? 'password' : 'text'" required />
      </mat-form-field>
      <p class="forgot-password">
        <button mat-flat-button>Password dimenticata?</button>
      </p>
    </form>
  </div> -->
</ng-template>

<div class="container-sk" style="text-align: center; bottom: 0; left: 0; position: fixed; width: 100%;">
  <div class="simple-keyboard"></div>
</div>