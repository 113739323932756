import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private jwtService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isCallToRapidApi = request.url.includes("rapidapi");
    const isCallTomyboardgames = request.url.includes("localhost");
    const isCallToLichess = request.url.includes("lichess");

    //if (this.jwtService.token && isCallTomyboardgames) {
    if (!isCallToLichess) {
      const token = this.jwtService.token;
      request = request.clone({
        setHeaders:
        {
          Authorization: `Bearer ${token}`
        }
      });
    }

    // https://imdb8.p.rapidapi.com/auto-complete
    else if (isCallToRapidApi) {
      request = request.clone({
        setHeaders:
        {
          'X-RapidAPI-Key': `da849f6cc7msh514d4427968455bp15d08ajsn0c30fcb3f590`,
          'X-RapidAPI-Host': `imdb8.p.rapidapi.com`
        }
      });
    }
    else {
      request = request.clone({
        setHeaders:
        {
          Authorization: `Bearer lip_r1774MUJRp2pzQs7nTFW`
        }
      });
    }
    return next.handle(request);
  }
}
