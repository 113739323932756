import { Friend } from './../models/Friend';
import { Utente } from './../models/Utente';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { __values } from 'tslib';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  displayedColumns: string[] = ['nameFriend', 'delete'];
  dataSourceTable: Friend[] = [];
  dataSource$: Observable<Friend[]> = this.http.get<Friend[]>(
    "https://localhost:44365/api/friends/getFriendsByUtenteId");
  options: Utente[]
  inputControl = new FormControl<string | Utente>('');
  filteredOptions: Observable<Utente[]>;
  utenteIsSelected = false;
  localSub = new Subscription();

  constructor(private ref: ChangeDetectorRef,
    private http: HttpClient) {
    this.loadDataFriends();
    this.loadDataUtenti();

  }

  loadDataFriends() {
    this.dataSource$.subscribe(val =>
      this.dataSourceTable = val
    );
  }

  loadDataUtenti() {
    const friends$: Observable<Utente[]> = this.http.get<Utente[]>(
      "https://localhost:44365/api/utenti/index");
    this.localSub = friends$.subscribe(
      val => {
        this.options = val
        console.table(val);
      });
  }



  ngOnInit() {
    this.filteredOptions = this.http.get<Utente[]>(
      "https://localhost:44365/api/utenti/index").pipe(switchMap((val) => {
        debugger;
        return this.inputControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            if (typeof value === 'string' && value.length < 2) {
              return;
            }
            this.utenteIsSelected = typeof value === 'object'
            const name = typeof value === 'string' ? value : value?.nome;
            return name ? this._filter(name as string) : val.slice();
          }),
        )
      }));
  }

  displayFn(user: Utente): string {
    return user && user.nome ? `${user.nome}  ${user.cognome}` : '';
  }

  private _filter(name: string): Utente[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(user => this.customFilter(user, filterValue));
  }
  customFilter(user: Utente, filter: string): boolean {
    const userFilter = (`${user.nome}  ${user.cognome}`).toLowerCase().includes(filter);
    const tableFilter = this.dataSourceTable.find(f => f.friendId === user.utenteId);
    const retVal = userFilter && (tableFilter === undefined);
    return retVal;
  }

  // private _filter(value: string): Utente[] {
  //   const filterValue = value.toLowerCase();

  //   return this.utenti.filter(utente => {
  //     (utente.nome + ' ' + utente.cognome).toLowerCase().includes(filterValue);
  //   });
  // }

  aggiungiAmico() {
    // controllare che this.myControl.value sia un utente
    const utenteSelected = this.inputControl.value as Utente;
    // if (!utenteSelected?.utenteId) {
    if (!this.utenteIsSelected) {
      throw new Error('Seleziona un nome dalla lista');
    }
    const utenteId = utenteSelected.utenteId;
    const utenteNome = `${utenteSelected.nome} ${utenteSelected.cognome}`;

    let friend: Friend = { friendId: utenteId, nameFriend: utenteNome };
    friend.friendId = utenteId;
    this.http.post<Friend>("https://localhost:44365/api/friends/create", friend).subscribe(val => {
      this.dataSourceTable.push(val);
      this.dataSourceTable = Array.from(this.dataSourceTable);
      this.clear();
      //this.filteredOptions = new Observable<Utente[]>();
    });
  }

  rimuoviAmico(id: string) {
    this.http.delete<Utente[]>("https://localhost:44365/api/friends/delete?id=" + id).subscribe();
    this.dataSourceTable = this.dataSourceTable.filter(f => f.friendId !== id);
  }

  ngOnDestroy(): void {
    this.localSub.unsubscribe();
  }

  clear() {
    this.inputControl.setValue(null);
    this.utenteIsSelected = false;
  }

}

export interface User {
  name: string;
}


