<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!-- <mat-toolbar color="primary">
  <mat-toolbar-row> -->
<!--  <span>HOME</span> -->
<!-- <span><a href="/">HOME</a></span>
    <span class="spacer"></span>
    <a mat-button routerLink="/register">Register</a>
    <a mat-button routerLink="/login">Login</a>
  </mat-toolbar-row>
</mat-toolbar> -->
<!-- <mat-toolbar *ngIf="sidenav.mode === 'over'" class="mat-elevation-z8">
  <button
    mat-icon-button

    (click)="sidenav.toggle()"
  >
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  Responsive side navigation
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav class="content mat-elevation-z8">
    <img
      class="avatar mat-elevation-z8"
      src="https://source.unsplash.com/c_GmwfHBDzk/200x200"
    />

    <h4 class="name">John Smith</h4>
    <p class="designation">Software Engineer</p>

    <mat-divider></mat-divider>

    <button routerLink="/register" mat-button class="menu-button">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>
    <button routerLink="/login" mat-button class="menu-button">
      <mat-icon>person</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-button class="menu-button">
      <mat-icon>info</mat-icon>
      <span>About</span>
    </button>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button">
      <mat-icon>help</mat-icon>
      <span>Help</span>
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z8"> -->
      <!-- Main content here! -->
      <router-outlet></router-outlet>
    <!-- </div>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
