import { AuthService } from './../auth/auth.service';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ConstantsService } from '../app.module';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent {
  @ViewChild(MatSidenav, { static: true }) sidenav!: MatSidenav;

  _currentRoute: string;

  public get currentRoute() {
    if (!this.router) {
      return '';
    }
    switch (this.router.url) {
      case '/search':
        return 'Cerca Serie';
      case '/friends':
        return 'Amici';
      case '/home':
        return 'Chess Memo';
      case '/memo':
        return 'Chess Memo';
      case '/games':
        return 'Partite';
      default:
        return '';
    }
  }

  constructor(
    private observer: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public authService: AuthService,
    public constnat: ConstantsService
  ) {
    router.url;
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
        this.cdr.detectChanges();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterContentChecked(): void {
    // this.cdr.detectChanges();
  }

  closeSideNav() {
    if (this.sidenav.mode === 'over') {
      this.sidenav.close();
    }
  }

  async installApp() {
    const result = await this.constnat.deferredPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
  }
}
