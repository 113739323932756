<div class="container-home">
  <mat-list>
    <!-- <div mat-subheader>Folders</div> -->
    <div *ngFor="let folder of folders">
      <mat-card>
        <div class="img-link">
          <img mat-card-md-image src="https://lucafrigenti.it/luca.png" alt="Foto Luca" />
          <i class="rank" [attr.data-star]="3.5"></i>
        </div>
        <div>
          <mat-card-title>Stranger things</mat-card-title>
          <mat-card-subtitle style="margin-top: 1%">Stagioni: 4-
          </mat-card-subtitle>
          <mat-card-subtitle>Categoria: Horror</mat-card-subtitle>
          <mat-card-subtitle><a class="link-IMDB" mat-flat-button href="https://google.com">IMDB
              <mat-icon>open_in_new</mat-icon></a></mat-card-subtitle>
          <mat-card-subtitle>#Luca</mat-card-subtitle>
        </div>
      </mat-card>
    </div>
    <!-- <mat-divider></mat-divider>
  <div mat-subheader>Notes</div>
  <mat-list-item *ngFor="let note of notes">
    <mat-icon mat-list-icon>note</mat-icon>
    <div mat-line>{{ note.name }}</div>
    <div mat-line>{{ note.updated | date }}</div>
  </mat-list-item> -->
  </mat-list>
  <div></div>
</div>