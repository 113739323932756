<div class="body-login">
  <mat-grid-list cols="1" rows="1" rowHeight="40em">
    <mat-grid-tile><mat-card class="login-card">
        <mat-card-title>Login</mat-card-title>
        <br />
        <!-- <mat-card-subtitle>Welcome</mat-card-subtitle> -->
        <mat-card-content>
          <form (ngSubmit)="login()">
            <mat-form-field appearance="standard">
              <mat-label>Email</mat-label>
              <input matInput [formControl]="form.controls['email']" required />
              <mat-error *ngIf="form.controls['email'].invalid">{{
                getErrorMessage(form.controls["email"])
                }}</mat-error>
              <button mat-icon-button matSuffix>
                <mat-icon matSuffix>mail</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>Password</mat-label>
              <input matInput [formControl]="form.controls['password']" [type]="hide ? 'password' : 'text'" required />
              <mat-error *ngIf="form.controls['password'].invalid">{{
                getErrorMessage(form.controls["password"])
                }}</mat-error>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
              </button>
            </mat-form-field>
            <!-- <p class="forgot-password">
              <button mat-flat-button>Password dimenticata?</button>
            </p> -->
          </form>
        </mat-card-content>
        <mat-card-actions>
          <!-- [disabled]="email.invalid || password.invalid" -->
          <button [disabled]="
              form.controls['email'].invalid ||
              form.controls['password'].invalid
            " *ngIf="!isLoading; else elseBlock" (click)="login()" class="button-login" mat-raised-button
            color="primary">
            Login
          </button>
          <ng-template #elseBlock>
            <button mat-button disabled>
              <mat-icon> <mat-spinner diameter="20"></mat-spinner></mat-icon>Loading
            </button>
          </ng-template>
          <!-- <p class="register">
          <button mat-flat-button>Registrati</button>
        </p> -->
          <br />
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>