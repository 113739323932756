import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstantsService } from '../app.module';
import { Router } from '@angular/router';
import { Game } from '../models/Game';


@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  games: Game[] = [];
  myName: string;
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private constants: ConstantsService,
    private router: Router
  ) {
    this.myName = this.authService.name;
  }

  ngOnInit() {
    this.http.get<any>(`${this.constants.URL_BASE}Game`).subscribe(
      val => {
        if (val.isSuccess) {
          // console.log("User is logged your token is:  " + val);
          // this.snackBar.open('Login effettuato con successo', 'X', {
          //   duration: 3000,
          //   panelClass: ['snackBar-success'],
          //   horizontalPosition: 'right',
          //   verticalPosition: 'top',
          // });
          this.games = val.list
          //this.router.navigateByUrl('/home');
        }
        else {
          this.snackBar.open(val.error, 'X', {
            panelClass: ['snackBar-warn'],
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        }
      },
      err => {
        console.log(err)
        let snackBarError = this.snackBar.open('HTTP Error: ' + err.error, 'X', {
          panelClass: ['snackBar-error'],
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
        snackBarError.onAction().subscribe(() => {
          snackBarError.dismiss();
        });
      }
    );
  }
  formateDate(dateString: string): string {
    const date = new Date(dateString);
    let ret: string;
    ret = ("0" + date.getDate()).slice(-2) + "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("0" + date.getHours()).slice(-2) + ":" +
      ("0" + date.getMinutes()).slice(-2) + ":" +
      ("0" + date.getSeconds()).slice(-2);
    return ret;
    //return new Date(dateString).toString();
  }
  openInLichess(pgn: string) {
    this.http.post<any>('https://lichess.org/api/import', {
      pgn: pgn
    }).subscribe(val => {
      window.open(val.url, "_self");
    });
  }
}