<!-- <p>Angular Material Dyanmic Responsive Grid Example</p> -->
<div class="content">
  <div fxLayout="row wrap" fxLayoutGap="16px" fxLa fxLayoutGap.xs="0px">
    <div class="card-flex" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card>
        <mat-card-content>
          <!-- CONTENT HERE -->
          <form class="my-form">
            <mat-form-field class="full-width">
              <mat-label>Cerca amico</mat-label>
              <input
                matInput
                [formControl]="inputControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{ option.nome }} {{ option.cognome }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <!-- REGISTER BUTTON -->
          <button
            mat-raised-button
            (click)="aggiungiAmico()"
            [disabled]="!utenteIsSelected"
            color="primary"
          >
            Aggiungi amico
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div
      *ngIf="dataSourceTable.length > 0"
      class="table-flex"
      fxFlex="20%"
      fxFlex.xs="100%"
      fxFlex.sm="33%"
    >
      <table
        style="overflow: auto; display: table"
        mat-table
        [dataSource]="dataSourceTable"
        class="mat-elevation-z8"
      >
        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <!-- <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>No.</th>
          <td mat-cell *matCellDef="let element">{{ element.position }}</td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="nameFriend">
          <th mat-header-cell *matHeaderCellDef><h2>Elenco amici</h2></th>
          <td mat-cell *matCellDef="let element">{{ element.nameFriend }}</td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="warn"
              (click)="rimuoviAmico(element.friendId)"
            >
              <mat-icon class="mat-18">delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <!-- <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Weight</th>
          <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
        </ng-container> -->

        <!-- Symbol Column -->
        <!-- <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef>Symbol</th>
          <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
