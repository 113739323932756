<div class="container-home">
    <mat-list>
        <!-- <div mat-subheader>games</div> -->
        <div *ngFor="let game of games">
            <!-- <mat-card>
                <div>
                    <mat-card-title style="width: max-content;">{{myName}} Vs {{game.opponentName}}</mat-card-title>
                    <mat-card-subtitle
                        style="margin-top: 1%">Data:{{this.formateDate(game.gameDate)}}</mat-card-subtitle>
                    <mat-card-subtitle>Vinta: {{game.isWinner ? 'Si' :
                        'No'}}</mat-card-subtitle>
                    <mat-card-subtitle>Colore: {{game.isWhite ? 'Bianco' :
                        'Nero'}}</mat-card-subtitle>
                    <mat-card-subtitle>Conclusa: {{game.isFinished ? 'Si' :
                        'No'}}</mat-card-subtitle>
                </div>
                <div class="buttonsInList card text-end">
                    <button mat-raised-button (click)="openInLichess(game.pgn)" color="primary">Invia a Lichess
                    </button>
                </div>
            </mat-card> -->

            <mat-card class="example-card">
                <mat-card-header style="display: inline;">
                    <mat-card-title align="center">{{myName}} Vs {{game.opponentName}}</mat-card-title>
                    <mat-card-subtitle align="center">Data: {{this.formateDate(game.gameDate)}}</mat-card-subtitle>
                    <!-- 
                    <mat-card-subtitle>Vinta: {{game.isWinner ? 'Si' :
                        'No'}}</mat-card-subtitle>
                    <mat-card-subtitle>Colore: {{game.isWhite ? 'Bianco' :
                        'Nero'}}</mat-card-subtitle>
                    <mat-card-subtitle>Conclusa: {{game.isFinished ? 'Si' :
                        'No'}}</mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content align="center">
                    <div>
                        <p>Vinta: {{game.isWinner ? 'Si' :
                            'No'}}</p>
                        <p>Colore: {{game.isWhite ? 'Bianco' :
                            'Nero'}}</p>
                        <p>Conclusa: {{game.isFinished ? 'Si' :
                            'No'}}</p>
                    </div>
                    <!-- <div style="display: grid;
                    align-content: end;">

                    </div> -->
                </mat-card-content>
                <mat-card-actions style="margin: 0; padding: 0;" align="center">
                    <button mat-raised-button (click)="openInLichess(game.pgn)" color="primary">Invia a Lichess
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <!-- <mat-divider></mat-divider>
    <div mat-subheader>Notes</div>
    <mat-list-item *ngFor="let note of notes">
      <mat-icon mat-list-icon>note</mat-icon>
      <div mat-line>{{ note.name }}</div>
      <div mat-line>{{ note.updated | date }}</div>
    </mat-list-item> -->
    </mat-list>
    <div></div>
</div>