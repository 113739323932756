import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import Keyboard from "simple-keyboard";
import { Chess } from 'chess.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Game } from '../models/Game';
import { ConstantsService } from '../app.module';
import { Router } from '@angular/router';

@Component({
    selector: 'app-memo',
    templateUrl: './memo.component.html',
    styleUrls: ['./memo.component.css']
})


export class MemoComponent {
    winner: string;
    myName: string;
    opponentName: string;
    myColourIsWhite: any;
    opponentColour: any;
    showCreatePGN = false;
    count = 0;
    valueTextInserted = "";
    keyboard: Keyboard;
    index = 0;
    arrayMoves = [];
    chess: Chess;
    pgn: string;
    hide = true;
    placeholder = `Inserisci la mossa`;
    form: FormGroup;
    game: Game;
    examplematch = `1. d3 d5 2. e4 e5 3. f4 Nc6 4. f5 d4 5. g4 Nf6 6. h4 Bd7 7. g5 Nxe4 8. dxe4 Nb4 9. h5 Ba4 10. b3 Bb5 11. Bxb5+ c6 12. Bd3 Qa5 13. Bd2 O-O-O 14. Bxb4 Qxb4+ 15.
Nd2 Qa3 16. Ngf3 Bb4 17. Nxe5 Rhf8 18. Nc4 Bxd2+ 19. Nxd2 Qb2 20. Nc4 Qc3+ 21.Qd2 Qxa1+ 22. Qd1 Qxa2 23. g6 fxg6 24. fxg6 hxg6 25. hxg6 Rf6 26. e5 Rxg6 27.
Bxg6 Rf8 28. Nd6+ Kc7 29. Qxd4 Qb1+ 30. Qd1 Qb2 31. Ne8+ Kb6 32. Rf1 Qc3+ 33.Qd2 Qxe5+ 34. Qe2 Qc3+ 35. Qd2 Rxf1+ 36. Ke2`





    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private constants: ConstantsService,
        private router: Router
    ) {
        // this.form = this.fb.group({
        //   mioNome: ['mioNome', []],
        //   avversarioNome: ['avversarioNome', []]
        // });
        this.myName = this.authService.name;
        this.chess = new Chess();
        // this.game.isFinished = true;
        // this.form = this.fb.group({
        //   email: ['', []],
        //   password: ['', []]
        // });
        this.game = {
            gameId: null,
            userId: '',
            isWhite: false,
            isWinner: false,
            gameDate: null,
            opponentName: null,
            pGN: null,
            location: null,
            isFinished: false,
        };

    }
    ngAfterViewInit() {
        if (this.showCreatePGN) {
            this.keyboard = new Keyboard({
                onChange: input => this.onChange(input),
                onKeyPress: button => this.onKeyPress(button),
                layout: {
                    default: [
                        "a b c d e f g h {shortCastling}",
                        "1 2 3 4 5 6 7 8 {longCastling}",
                        "♘ ♗ ♖ ♕ ♔ {backspace}",
                        "x + # = {enter}",
                    ]
                },
                buttonTheme: [
                    {
                        class: "hg-red",
                        buttons: "q w e r t y u i o p"
                    },
                    {
                        class: "hg-green",
                        buttons: "a s d f g h j k l"
                    },
                    {
                        class: "hg-blue",
                        buttons: "z x c v b n m"
                    },
                    {
                        class: "hg-white",
                        buttons: "{shift} {bksp} {enter}"
                    }
                ],
                display: {
                    "{backspace}": "⌫",
                    "{enter}": "↩",
                    "{longCastling}": "O-O-O",
                    "{shortCastling}": "O-O"
                }
            });
            // this.chess.loadPgn('1. d3 d5 2. e4 e5 3. f4 Nc6 4. f5 d4 5. g4 Nf6 6. h4 Bd7 7. g5 Nxe4 8. dxe4 Nb4 9. h5 Ba4 10. b3 Bb5 11. Bxb5+ c6 12. Bd3 Qa5 13. Bd2 O-O-O 14. Bxb4 Qxb4+');
            //this.chess.loadPgn('1. e4 c6 2. d4 d5 3. Bd3 Nf6 4. e5 Nfd7 5. e6 fxe6 6. Qh5+ g6 7. Qxg6+ hxg6');
            // this.chess.loadPgn(this.examplematch);
            this.pgn = this.chess.pgn({ maxWidth: 15, newline: '<br />' });
        }
    }

    onChange = (input: any) => {
        this.valueTextInserted = this.stringToPgn(input);
        console.log("Input changed", input);
        console.log("valueTextInserted", this.valueTextInserted);
        const listMovesElement = document.querySelector('.list-moves');
        const dest = listMovesElement.scrollHeight + (listMovesElement.scrollHeight / 100) * 3;
        listMovesElement.scrollTo(0, dest);
    };

    onKeyPress = (button: string) => {
        this.count++;
        console.log("Button pressed", button);
        switch (button) {
            case '{shortCastling}':
                this.valueTextInserted = 'O-O';
                break;
            case '{longCastling}':
                this.valueTextInserted = 'O-O-O';
                break;
            case '{backspace}':
                if (this.valueTextInserted === 'O-O-O' || this.valueTextInserted === 'O-O') {
                    this.valueTextInserted = '';
                }
                break;
        }

        if (this.valueTextInserted && button == '{enter}') {
            this.checkValidMove(this.valueTextInserted);
            this.arrayMoves = this.chess.history();
            this.pgn = this.chess.pgn({ maxWidth: 15, newline: '<br />' });
            this.keyboard.clearInput();
            this.game.isFinished = this.checkFinishedGame();

        }
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        console.log(this.count);
    };

    checkFinishedGame(): boolean {
        if (!this.chess.isGameOver()) {
            return false;
        }
        if (this.chess.isCheckmate()) {
            if (this.chess.turn() == 'b') {
                this.winner = this.myColourIsWhite ? this.authService.name : this.opponentName;
            }
            else {
                this.winner = !this.myColourIsWhite ? this.authService.name : this.opponentName;
            }
            this.game.isWinner = this.winner == this.authService.name;
            let snackBarError = this.snackBar.open(`${this.winner} vince per scacco matto!`, 'X', {
                panelClass: ['snackBar-success'],
                //duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'top'
            });
            snackBarError.onAction().subscribe(() => {
                snackBarError.dismiss();
            });
        }
        let causeOfEndGame = '';
        if (this.chess.isDraw()) {
            causeOfEndGame = 'La partita è finita patta';
        }
        if (this.chess.isInsufficientMaterial()) {
            causeOfEndGame = 'La partita è finita per insufficenza di materiale';
        }
        if (this.chess.isStalemate()) {
            causeOfEndGame = 'La partita è finita per stallo';
        }
        if (this.chess.isThreefoldRepetition()) {
            causeOfEndGame = 'La partita è finita per tripla ripetizione';
        }
        return true;
    }
    onInputChange = (event: any) => {
        this.keyboard.setInput(event.target.value);
        console.log(event)
    };
    handleShift = () => {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    };
    checkValidMove(move: string): boolean {
        if (move == 'NNN') {
            this.chess.loadPgn(this.examplematch);
            return;
        }
        try {
            this.chess.move(move);
            return true;
        } catch (error) {
            let snackBarError = this.snackBar.open(`La mossa: ${move} non è consentita!`, 'X', {
                panelClass: ['snackBar-error'],
                duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'top'
            });
            snackBarError.onAction().subscribe(() => {
                snackBarError.dismiss();
            });
            return false;
        }
    }
    stringToPgn(input: string): string {
        input = input.trim();
        input = input.replaceAll('♕', 'Q');
        input = input.replaceAll('♘', 'N');
        input = input.replaceAll('♗', 'B');
        input = input.replaceAll('♔', 'K');
        input = input.replaceAll('♖', 'R');
        return input;
    }
    selectionChange(event) {
        if (event.source.ngControl.name === 'me') {
            this.opponentColour = !event.value;
        }
        if (event.source.ngControl.name === 'opponent') {
            this.myColourIsWhite = !event.value;
        }
    }
    startGame() {
        this.showCreatePGN = true;
        this.game.isWhite = this.myColourIsWhite;
        this.game.opponentName = this.opponentName;
        this.game.gameDate = new Date();
        this.game.gameId = generateGUID();
        this.game.userId = this.authService.sid;

        this.ngAfterViewInit();
    }
    savePGN() {
        const date = this.formatDate(this.game.gameDate);
        const white = this.myColourIsWhite ? this.authService.name : this.opponentName;
        const black = white == this.authService.name ? this.opponentName : this.authService.name;
        this.chess.header("White", white, "Black", black, "Date", date)
        this.game.pGN = btoa(this.chess.pgn());
        this.http.post<any>(`${this.constants.URL_BASE}Game`, this.game).subscribe(
            val => {
                if (val.isSuccess) {
                    // console.log("User is logged your token is:  " + val);
                    this.snackBar.open('Partita salvata', 'X', {
                        duration: 3000,
                        panelClass: ['snackBar-success'],
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                    this.router.navigateByUrl('/games');
                }
                else {
                    this.snackBar.open(val.error, 'X', {
                        panelClass: ['snackBar-warn'],
                        duration: 3000,
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                    });
                }
            },
            err => {
                console.log(err)
                let snackBarError = this.snackBar.open('Server Error: ' + err.error, 'X', {
                    panelClass: ['snackBar-error'],
                    duration: 5000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
                snackBarError.onAction().subscribe(() => {
                    snackBarError.dismiss();
                });
            }
        );
    }

    undo() {
        //this.router.navigateByUrl('/search');
        this.chess.undo();
        this.pgn = this.chess.pgn({ maxWidth: 15, newline: '<br />' });
    }

    test() {
        console.log(this.chess.pgn);
    }

    formatDate(date: Date): string {
        let formattedDate = date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + date.getDate()).slice(-2) + ' ' +
            ('0' + date.getHours()).slice(-2) + ':' +
            ('0' + date.getMinutes()).slice(-2) + ':' +
            ('0' + date.getSeconds()).slice(-2);
        return formattedDate;
    }
}

function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
/*
    
    "a e 1 5 ♘ ♗",
      "b f 2 6 ♖ x",
      "c g 3 7 ♕ +",
      "d h 4 8 ♔ #",

*/
