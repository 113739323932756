<mat-toolbar *ngIf="sidenav.mode === 'over'" class="mat-elevation-z8">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <div style="text-align: center; width: 100%">
    {{ currentRoute }}
  </div>
  <!-- Responsive side navigation -->
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav class="content mat-elevation-z8">
    <img src="https://lucafrigenti.it/luca.png" width="100" />

    <!-- src="https://lucafrigenti.it/luca.png"  class="avatar mat-elevation-z8" -->
    <h4 class="name">{{ authService.name }} {{ authService.surname }}</h4>
    <!-- <p class="designation">Software Engineer</p> -->

    <mat-divider></mat-divider>

    <!-- <button (click)="closeSideNav()" routerLink="/home" mat-button class="menu-button">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>
    <button (click)="closeSideNav()" routerLink="/amici" mat-button class="menu-button">
      <mat-icon>group</mat-icon>
      <span>Amici</span>
    </button> -->
    <button *ngIf="sidenav.mode === 'over'" (click)="closeSideNav()" routerLink="/memo" mat-button class="menu-button">
      <mat-icon>app_registration</mat-icon>
      <span>Memo</span>
    </button>
    <button (click)="closeSideNav()" routerLink="/games" mat-button class="menu-button">
      <mat-icon>search</mat-icon>
      <span>Partite</span>
    </button>

    <mat-divider></mat-divider>

    <button (click)="installApp()" mat-button class="menu-button">
      <mat-icon>install_mobile</mat-icon>
      <span>Installa</span>
    </button>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z8">
      <!-- Main content here! -->
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>